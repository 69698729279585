import React from "react"
import { SocialIcon } from "."

const SocialIconLink = ({
  platform,
  link,
  className = "",
  iconColor,
  size,
  borderRadius
}) => {
  if (!link || !platform) return ""

  const styles = {
    width: size || "40px",
    height: size || "40px",
    borderRadius: borderRadius || "100%"
  }

  return (
    <a
      className={`social-icon-link ${className}`}
      href={link}
      title={`Follow us on ${platform}`}
      target="_blank"
      style={styles}
      rel="noopener noreferrer"
    >
      <SocialIcon color={iconColor} platform={platform} />
    </a>
  )
}

export default SocialIconLink
