import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"

import "./socialIcons.scss"

const SocialIcon = ({ social, platform, color }) => {
  let icon
  let classname = "icon-circle"
  if (platform === "Facebook") {
    icon = faFacebookF
    classname += " fb"
  }
  if (platform === "Instagram") {
    icon = faInstagram
    classname += " insta"
  }

  return (
    <div className={classname}>
      <FontAwesomeIcon className="button-icon" icon={icon} />
    </div>
  )
}

export default SocialIcon
